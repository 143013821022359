import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "./about.module.css"

import headerImage from "../images/about_stodgy_header.jpg"

const IndexPage = ({ data }) => (
    <Layout>
        <SEO title="About" />
        <img src={headerImage} className={aboutStyles.backgroundImage} alt="Wood fence with vine."/>
        <div className={aboutStyles.backgroundColor}></div>
        <div className={aboutStyles.backgroundColorTwo}></div>
        <div className={aboutStyles.titleSection}>
            <h1 className={aboutStyles.title}>YOUR<br />NEIGHBORHOOD<br />TAPROOM</h1>
            <p className={aboutStyles.subtitle}>IN NORTH-WEST FORT COLLINS</p>
        </div>
        <div className={aboutStyles.definitionSection}>
            <div className={aboutStyles.chairImage}>
                <Img fluid={data.chairImage.childImageSharp.fluid} alt="Stodgy looking chair."  />
            </div>
            <div className={aboutStyles.definitionContent}>
                <p className={aboutStyles.definitionStodgy}>STODGY</p>
                <h2>What's<br />in a name?</h2>
                <p className={aboutStyles.definitionSubtitle}>WE KNOW YOU'RE CURIOUS</p>
                <p className={aboutStyles.definitionPronunciation}><b>[stah-gee]</b> <em>adjective</em></p>
                <p className={aboutStyles.definitionBody}>Dull, uninspired.  We take it to mean simple and unpretentious.  It also means heavy and filling, full of carbs.  The perfect name for this Stodgy place.</p>
            </div>
        </div>
        <div className={aboutStyles.missionSection}>
            <p>We are a casual neighborhood gathering space in north-west Fort Collins, where people can connect and relax around great beer.  We are founded by a brewer and a landscaper and we have a large outdoor patio and cozy indoor spaces to drink beer.</p>
        </div>
        {/* <div className={aboutStyles.ownerSection}>
            <div className={aboutStyles.ownerOne}>
                <div className={aboutStyles.natalieImage}>
                    <Img fluid={data.natalieImage.childImageSharp.fluid} alt="Natalie, owner of Stodgy Brewing."  />
                    <p className={aboutStyles.ownerOneName}>NATALIE</p>
                </div>
                <h2 className={aboutStyles.ownerTitle}>OWNER</h2>
                <p className={aboutStyles.ownerBio}>Natalie, a former farmer and horticulturist, has a strong background in business ownership, landscaping, agriculture and construction. Much of the finish work in the brewery and all of the landscaping was installed by Natalie, and she’s also responsible for procuring local ingredients for our beer.  We proudly serve beer that is brewed with as many local ingredients as possible (most of our beers are made with 100% Colorado grown ingredients!) and we’re constantly working towards more Colorado ingredients!</p>
            </div>
            <div className={aboutStyles.ownerTwo}>
                <div className={aboutStyles.tylerImage}>
                    <Img fluid={data.tylerImage.childImageSharp.fluid} alt="Tyler, owner and brewer of Stodgy Brewing."  />
                    <p className={aboutStyles.ownerTwoName}>TYLER</p>
                </div>
                <h2 className={aboutStyles.ownerTitle}>OWNER & BREWER</h2>
                <p className={aboutStyles.ownerBio}>Tyler has been working on the bar side of breweries for the past 10 years while honing his beer making skills at home and as a guest brewer at commercial breweries.  His attention to detail and his drive to make and drink incredible beer is why our beer tastes so good!  His bartending experience really helped with taproom flow and making sure we created a workplace that bartenders and customers would really enjoy.</p>
            </div>
        </div> */}
        <div className={aboutStyles.historySection}>
            <div className={aboutStyles.friendlyFireImage}>
                <Img fluid={data.friendlyFireImage.childImageSharp.fluid} alt="Old photo of the Friendly Fire."  />
                <p className={aboutStyles.historyLandmark}>A LOCAL LANDMARK</p>
            </div>
            <div className={aboutStyles.historyFirstParagraph}>
                <div className={aboutStyles.verticalLineFirst}></div>
                <p className={aboutStyles.historyTopText}>If you’re a long-time Fort Collins<br />resident, you probably know our building.</p>
                <p className={aboutStyles.historyBottomText}>Maybe you’ve driven by a hundred times and wondered...what is that place? You may have once bought a stove there. Originally part of the Forney Industries complex, 1802 Laporte Avenue was best known as the Friendly Fire building, a long standing stove and fireplace store from 1974-2007.</p>
            </div>
            <div className={aboutStyles.verticalLine}></div>
            <p className={aboutStyles.historyTopText}>We're not sure there's<br /> anything this place hasn't been.</p>
            <p className={aboutStyles.historyBottomText}>35 (ish) chimneys, a fireplace-waterfall combo, a 4-ton masonry stove, a vacuum-controlled styrofoam bead insulation system, and a sloped video projection wall are just some of the features this building has boasted over the years. Rumor has it there was a basketball court in there at one point. And a hot tub showroom. Since then it’s been sitting for sale, begging to be turned into something awesome.</p>
            <div className={aboutStyles.verticalLine}></div>
            <p className={aboutStyles.historyTopText}>It’s perfect for us.</p>
            <p className={aboutStyles.historyBottomText}>The minute we stepped inside this space, we knew it was the right spot for Stodgy. Developing 1802 Laporte Ave. into Stodgy Brewing Company wasn’t exactly a straightforward or easy process, but it was worth it! The character of this building is hard to beat, and is the perfect backdrop for our brewery and taproom.</p>
            <div className={aboutStyles.verticalLine}></div>
            <p className={aboutStyles.historyTopText}>The process of converting<br />this building was far from typical.</p>
            <p className={aboutStyles.historyBottomText}>We attempted to reclaim every good piece of wood or partially whole piece of slate.  We reused the old wood floors for the ceiling and turned soapstone wood-burning stove parts into a unique tile floor!  All of the trim in our building is fence wood from the site, and our bar is made of black walnut that grew on this property.</p>
        </div>
    </Layout>
)

export const query = graphql`
  query {
    chairImage: file(relativePath: { eq: "chair_stodgy_definition.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    natalieImage: file(relativePath: { eq: "Natalie_mugshot_color.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    tylerImage: file(relativePath: { eq: "Tyler_mugshot_color.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    friendlyFireImage: file(relativePath: { eq: "FriendlyFire_history.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
    }
  }
`

export default IndexPage
